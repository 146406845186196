var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',{attrs:{"container-type":"centered"}},[_c('page-row',[_c('page-column',[_c('echo-form',{attrs:{"title":_vm.$t('mooring.title'),"explanation":_vm.$t('mooring.explanation'),"model":_vm.model,"form":_vm.form,"submit-action":"REQUEST_OUTSIDE_THE_PET","data-test":"form-outside-the-pet"}},[_c('field-set',{attrs:{"legend":_vm.$t('view.visit.title'),"data-test":"visit"}},[_c('input-ucrn',{attrs:{"form":_vm.form,"model":_vm.model,"model-key":"ucrn","validators":{
              required: { messageTranslationKey: 'form.ucrn.required' },
              myUcrn: true,
            },"label":_vm.$t('form.ucrn.label'),"data-test":"ucrn"}}),(_vm.vesselForSummary)?_c('ship-summary',{attrs:{"vessel":_vm.vesselForSummary}}):_vm._e(),_c('input-select',{attrs:{"form":_vm.form,"model":_vm.model,"model-key":"berthVisitId","validators":{
              required: { messageTranslationKey: 'form.berth.required' },
              notMoerdijk: _vm.visit && { visit: _vm.visit, blocking: true },
            },"label":_vm.$t('form.berth.label'),"placeholder":_vm.$t('form.berth.placeholder'),"options":_vm.berthOptions}}),_c('input-select-or-custom',{attrs:{"form":_vm.form,"model":_vm.model,"model-key":"reason","validators":{ required: true, maxLength: { length: 2000 } },"label":_vm.$t('mooring.reason.label'),"placeholder":_vm.$t('mooring.reason.placeholder'),"options":[
              {
                text: _vm.$t('mooring.reason.options.transfer'),
                value: 'Overslag',
              },
              {
                text: _vm.$t('mooring.reason.options.repair'),
                value: 'Reparatie',
              },
              {
                text: _vm.$t('mooring.reason.options.clean'),
                value: 'Schoonmaken',
              },
              {
                text: _vm.$t('mooring.reason.options.wait'),
                value: 'Wachten',
              } ]}})],1),_c('field-separator'),_c('field-set',{attrs:{"legend":_vm.$t('mooring.tanks.title'),"data-test":"tanks"}},[_c('input-radio',{attrs:{"form":_vm.form,"model":_vm.model,"model-key":"shipCleanState","validators":{ required: true },"label":_vm.$t('mooring.tanks.label'),"options":_vm.shipCleanStateOptions,"data-test":"ship-clean-state"}}),('CLEAN' === _vm.model.shipCleanState)?_c('field-set',{staticClass:"boxed-fieldset",attrs:{"legend":_vm.$t('mooring.tanks.additionalInfo.title'),"description":_vm.$t('mooring.tanks.additionalInfo.description'),"data-test":"tanks-clean"}},[_c('input-file',{attrs:{"form":_vm.form,"model":_vm.model,"model-key":"attachments_LastCargoDescriptionClean","validators":{ files: { maxFiles: 10 } },"data-test":"attachments-tanks-clean"}}),_c('input-textarea',{key:"lastCargoDescription",attrs:{"form":_vm.form,"model":_vm.model,"model-key":"lastCargoDescription","validators":{
                required: !_vm.model.attachments_LastCargoDescriptionClean && !_vm.model.attachments,
                maxLength: { length: 2000 },
              },"label":_vm.$t('mooring.tanks.number.label'),"placeholder":_vm.$t('mooring.tanks.number.placeholder')}})],1):_vm._e(),('NOT_CLEAN' === _vm.model.shipCleanState)?_c('field-set',{staticClass:"boxed-fieldset",attrs:{"legend":_vm.$t('mooring.tanks.additionalInfo.title'),"description":_vm.$t('mooring.tanks.additionalInfo.description'),"data-test":"tanks-not-clean"}},[_c('input-file',{attrs:{"form":_vm.form,"model":_vm.model,"model-key":"attachments_LastCargoDescriptionNotClean","validators":{ files: { maxFiles: 10 } },"data-test":"attachments-tanks-not-clean"}}),_c('input-textarea',{key:"cargoTanksDescription",attrs:{"form":_vm.form,"model":_vm.model,"model-key":"cargoTanksDescription","validators":{
                required: !_vm.model.attachments_LastCargoDescriptionNotClean && !_vm.model.attachments,
                maxLength: { length: 2000 },
              },"label":_vm.$t('mooring.tanks.title'),"placeholder":_vm.$t('mooring.tanks.placeholder')}}),_c('input-textarea',{attrs:{"form":_vm.form,"model":_vm.model,"model-key":"slopTanksDescription","validators":{ required: !_vm.model.attachments_LastCargoDescriptionNotClean, maxLength: { length: 2000 } },"label":_vm.$t('mooring.tanks.slop'),"placeholder":_vm.$t('mooring.tanks.placeholder')}}),_c('input-yes-no',{attrs:{"form":_vm.form,"model":_vm.model,"model-key":"hasInertTanks","options":[
                { value: true, text: _vm.$t('generic.options.yes') },
                { value: false, text: _vm.$t('generic.options.no') } ],"validators":{ required: true },"question":_vm.$t('mooring.tanks.inert.question'),"data-test":"inert-tanks"}}),(_vm.model.hasInertTanks)?_c('input-textarea',{attrs:{"form":_vm.form,"model":_vm.model,"model-key":"inertTanksDescription","validators":{ required: true, maxLength: { length: 2000 } },"label":_vm.$t('mooring.tanks.inert.label'),"label-hidden":true,"placeholder":_vm.$t('mooring.tanks.inert.placeholder')}}):_vm._e()],1):_vm._e(),_c('field-separator'),('CONSIDERED_SAFE' !== _vm.model.shipCleanState)?_c('input-yes-no',{attrs:{"form":_vm.form,"model":_vm.model,"model-key":"hasReportFromGasExpert","options":[
              { value: true, text: _vm.$t('generic.options.yes') },
              { value: false, text: _vm.$t('generic.options.no') } ],"validators":{
              required: true,
              is: {
                validValue: true,
                messageTranslationKey: 'mooring.gasReport.message',
                blocking: true,
              },
            },"question":_vm.$t('mooring.gasReport.question'),"data-test":"report-gas-expert"}}):_vm._e(),(_vm.model.hasReportFromGasExpert)?_c('input-file',{attrs:{"label":_vm.$t('mooring.gasReport.label'),"form":_vm.form,"model":_vm.model,"validators":{ files: { maxFiles: 10 } },"model-key":"attachments_ReportGasExpert"}}):_vm._e(),(_vm.copy && _vm.model.attachments)?_c('input-file',{attrs:{"label":_vm.$t('form.input.attachments'),"form":_vm.form,"model":_vm.model,"validators":{ files: { maxFiles: 10 } },"model-key":"attachments","data-test":"attachments-copy"}}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }