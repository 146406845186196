<template>
  <page container-type="centered">
    <page-row>
      <page-column>
        <echo-form
          :title="$t('mooring.title')"
          :explanation="$t('mooring.explanation')"
          :model="model"
          :form="form"
          submit-action="REQUEST_OUTSIDE_THE_PET"
          data-test="form-outside-the-pet"
        >
          <field-set :legend="$t('view.visit.title')" data-test="visit">
            <input-ucrn
              :form="form"
              :model="model"
              model-key="ucrn"
              :validators="{
                required: { messageTranslationKey: 'form.ucrn.required' },
                myUcrn: true,
              }"
              :label="$t('form.ucrn.label')"
              data-test="ucrn"
            />

            <ship-summary v-if="vesselForSummary" :vessel="vesselForSummary" />

            <input-select
              :form="form"
              :model="model"
              model-key="berthVisitId"
              :validators="{
                required: { messageTranslationKey: 'form.berth.required' },
                notMoerdijk: visit && { visit, blocking: true },
              }"
              :label="$t('form.berth.label')"
              :placeholder="$t('form.berth.placeholder')"
              :options="berthOptions"
            />

            <input-select-or-custom
              :form="form"
              :model="model"
              model-key="reason"
              :validators="{ required: true, maxLength: { length: 2000 } }"
              :label="$t('mooring.reason.label')"
              :placeholder="$t('mooring.reason.placeholder')"
              :options="[
                {
                  text: $t('mooring.reason.options.transfer'),
                  value: 'Overslag',
                },
                {
                  text: $t('mooring.reason.options.repair'),
                  value: 'Reparatie',
                },
                {
                  text: $t('mooring.reason.options.clean'),
                  value: 'Schoonmaken',
                },
                {
                  text: $t('mooring.reason.options.wait'),
                  value: 'Wachten',
                },
              ]"
            />
          </field-set>

          <field-separator />

          <field-set :legend="$t('mooring.tanks.title')" data-test="tanks">
            <input-radio
              :form="form"
              :model="model"
              model-key="shipCleanState"
              :validators="{ required: true }"
              :label="$t('mooring.tanks.label')"
              :options="shipCleanStateOptions"
              data-test="ship-clean-state"
            />

            <field-set
              v-if="'CLEAN' === model.shipCleanState"
              class="boxed-fieldset"
              :legend="$t('mooring.tanks.additionalInfo.title')"
              :description="$t('mooring.tanks.additionalInfo.description')"
              data-test="tanks-clean"
            >
              <input-file
                :form="form"
                :model="model"
                model-key="attachments_LastCargoDescriptionClean"
                :validators="{ files: { maxFiles: 10 } }"
                data-test="attachments-tanks-clean"
              />

              <input-textarea
                key="lastCargoDescription"
                :form="form"
                :model="model"
                model-key="lastCargoDescription"
                :validators="{
                  required: !model.attachments_LastCargoDescriptionClean && !model.attachments,
                  maxLength: { length: 2000 },
                }"
                :label="$t('mooring.tanks.number.label')"
                :placeholder="$t('mooring.tanks.number.placeholder')"
              />
            </field-set>

            <field-set
              v-if="'NOT_CLEAN' === model.shipCleanState"
              class="boxed-fieldset"
              :legend="$t('mooring.tanks.additionalInfo.title')"
              :description="$t('mooring.tanks.additionalInfo.description')"
              data-test="tanks-not-clean"
            >
              <input-file
                :form="form"
                :model="model"
                model-key="attachments_LastCargoDescriptionNotClean"
                :validators="{ files: { maxFiles: 10 } }"
                data-test="attachments-tanks-not-clean"
              />

              <input-textarea
                key="cargoTanksDescription"
                :form="form"
                :model="model"
                model-key="cargoTanksDescription"
                :validators="{
                  required: !model.attachments_LastCargoDescriptionNotClean && !model.attachments,
                  maxLength: { length: 2000 },
                }"
                :label="$t('mooring.tanks.title')"
                :placeholder="$t('mooring.tanks.placeholder')"
              />

              <input-textarea
                :form="form"
                :model="model"
                model-key="slopTanksDescription"
                :validators="{ required: !model.attachments_LastCargoDescriptionNotClean, maxLength: { length: 2000 } }"
                :label="$t('mooring.tanks.slop')"
                :placeholder="$t('mooring.tanks.placeholder')"
              />

              <input-yes-no
                :form="form"
                :model="model"
                model-key="hasInertTanks"
                :options="[
                  { value: true, text: $t('generic.options.yes') },
                  { value: false, text: $t('generic.options.no') },
                ]"
                :validators="{ required: true }"
                :question="$t('mooring.tanks.inert.question')"
                data-test="inert-tanks"
              />

              <input-textarea
                v-if="model.hasInertTanks"
                :form="form"
                :model="model"
                model-key="inertTanksDescription"
                :validators="{ required: true, maxLength: { length: 2000 } }"
                :label="$t('mooring.tanks.inert.label')"
                :label-hidden="true"
                :placeholder="$t('mooring.tanks.inert.placeholder')"
              />
            </field-set>

            <field-separator />

            <input-yes-no
              :form="form"
              :model="model"
              v-if="'CONSIDERED_SAFE' !== model.shipCleanState"
              model-key="hasReportFromGasExpert"
              :options="[
                { value: true, text: $t('generic.options.yes') },
                { value: false, text: $t('generic.options.no') },
              ]"
              :validators="{
                required: true,
                is: {
                  validValue: true,
                  messageTranslationKey: 'mooring.gasReport.message',
                  blocking: true,
                },
              }"
              :question="$t('mooring.gasReport.question')"
              data-test="report-gas-expert"
            />

            <input-file
              v-if="model.hasReportFromGasExpert"
              :label="$t('mooring.gasReport.label')"
              :form="form"
              :model="model"
              :validators="{ files: { maxFiles: 10 } }"
              model-key="attachments_ReportGasExpert"
            />

            <input-file
              v-if="copy && model.attachments"
              :label="$t('form.input.attachments')"
              :form="form"
              :model="model"
              :validators="{ files: { maxFiles: 10 } }"
              model-key="attachments"
              data-test="attachments-copy"
            />
          </field-set>
        </echo-form>
      </page-column>
    </page-row>
  </page>
</template>

<script>
import FieldSeparator from 'poronline-shared-vue-components/components/form/field-separator';
import FieldSet from 'poronline-shared-vue-components/components/form/field-set';
import InputUcrn from 'poronline-shared-vue-components/components/form/input-ucrn';
import InputSelect from 'poronline-shared-vue-components/components/form/input-select';
import InputSelectOrCustom from 'poronline-shared-vue-components/components/form/input-select-or-custom';
import InputRadio from 'poronline-shared-vue-components/components/form/input-radio';
import InputTextarea from 'poronline-shared-vue-components/components/form/input-textarea';
import InputYesNo from 'poronline-shared-vue-components/components/form/input-yes-no';
import PageRow from 'poronline-shared-vue-components/components/page/row';
import PageColumn from 'poronline-shared-vue-components/components/page/column';
import EchoForm from '../../components/form/echo-form';
import InputFile from '../../components/form/input-file';
import ShipSummary from '../../components/form/ship-summary';
import withVisit from '../../components/form/with-visit';
import Page from '../../components/page/page';

export default {
  mixins: [withVisit],
  components: {
    Page,
    PageRow,
    PageColumn,
    EchoForm,
    FieldSeparator,
    FieldSet,
    InputUcrn,
    InputSelect,
    InputSelectOrCustom,
    InputRadio,
    InputTextarea,
    InputYesNo,
    InputFile,
    ShipSummary,
  },
  props: {
    copy: Object,
  },
  data() {
    const model = {};

    if (this.copy) {
      Object.assign(model, this.copy);
    }

    if (this.$route.query.ucrn) {
      model.ucrn = this.$route.query.ucrn;
    }

    return { model, form: {} };
  },
  computed: {
    vesselForSummary() {
      return this.model.ucrn && this.form?.ucrn?.$valid
        ? {
            shipName: this.selectedVessel?.shipName,
            shipNumber: this.selectedVessel?.shipNumber,
            category: this.selectedVessel?.category,
            length: this.selectedVessel?.length,
            beam: this.selectedVessel?.beam,
          }
        : undefined;
    },
    shipCleanStateOptions() {
      return ['CONSIDERED_SAFE', 'CLEAN', 'NOT_CLEAN'].map((value) => ({
        value,
        text: this.$t(`mooring.shipCleanState.${value}`),
      }));
    },
  },
};
</script>
